<template>
  <div></div>
</template>

<script>
import XLSX from "xlsx/dist/xlsx.mini.min";
import saveAs from "file-saver/dist/FileSaver.min.js";
export default {
  name: "Export",
  props: {
    appData: Object,
    selectedSeason: Object,
    selectedSeasonShortTitle: String,
  },
  mounted() {
    this.createSpreadSheet();
    this.$emit("finished");
  },
  methods: {
    getUserById(id) {
      return this.appData.users.find((user) => user.id === id);
    },
    createSpreadSheet() {
      let s2ab = (s) => {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };
      let sheetData = [
        [`Pikettdienst Geologie Naturgefahren`],
        [`Einsatzliste ${this.selectedSeasonShortTitle}`],
        [],
        [
          `Achtung: Ein Pikettdienst-Einsatz-Tag startet jeweils erst um 8:00 und dauert bis um 8:00 des darauffolgenden Tages.`,
        ],
        [],
        [
          "Datum",
          "Name",
          "Rufnummer",
          //"Rufnummer 2",
          "Name Stv.",
          "Rufnummer Stv.",
          //"Rufnummer 2 Stv.",
        ],
      ];
      this.selectedSeason.periods.forEach((period) => {
        period.days.forEach((day) => {
          let p = this.getUserById(parseInt(day.p));
          let s = this.getUserById(parseInt(day.s));
          sheetData.push([
            day.date.split("-").reverse().join("."),
            p ? `${p.firstname} ${p.familyname}` : "",
            p ? p.mobile : "",
            //p ? p.phone : "",
            s ? `${s.firstname} ${s.familyname}` : "",
            s ? s.mobile : "",
            //s ? s.phone : "",
          ]);
        });
      });
      let sheet = XLSX.utils.aoa_to_sheet(sheetData, { cellDates: true });
      let cellMerges = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },
        { s: { r: 3, c: 0 }, e: { r: 3, c: 4 } },
      ];
      sheet["!merges"] = cellMerges;
      let book = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(book, sheet, this.selectedSeasonShortTitle);
      let blob = new Blob(
        [s2ab(XLSX.write(book, { bookType: "xlsx", type: "binary" }))],
        {
          type: "application/octet-stream",
        }
      );
      return saveAs(blob, `pikettplan-${this.selectedSeasonShortTitle}.xlsx`);
    },
  },
};
</script>